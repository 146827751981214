export const baseColors = {
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent",

  darkBlue: "#222528",
  brightBlue: "#0085FF",
};

export const lightModeColors = {
  ...baseColors,
  primary: baseColors.darkBlue,
  secondary: baseColors.white,

  bgColor: baseColors.white,
  fgColor: baseColors.darkBlue,

  accent: baseColors.brightBlue,
};

export const darkModeColors = {
  ...baseColors,
  primary: baseColors.white,
  secondary: baseColors.darkBlue,

  bgColor: baseColors.darkBlue,
  fgColor: baseColors.white,

  accent: baseColors.brightBlue,
};
