import { ChangeEvent } from "react";

import { NumberPickerProps } from "./types";
import { NumberPickerContainer, Button, InputField } from "./styled";

const NumberPicker = ({
  min,
  max,
  value,
  onNumberChange,
}: NumberPickerProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      onNumberChange(newValue);
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      onNumberChange(value - 1);
    }
  };

  const handleIncrement = () => {
    if (value < max) {
      onNumberChange(value + 1);
    }
  };

  return (
    <NumberPickerContainer>
      <Button onClick={handleDecrement}>-</Button>
      <InputField
        type="number"
        value={value}
        min={min}
        max={max}
        onChange={handleChange}
      />
      <Button onClick={handleIncrement}>+</Button>
    </NumberPickerContainer>
  );
};

export default NumberPicker;
