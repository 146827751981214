import styled from 'styled-components';

export const App = styled.div`
  position: fixed;
  left: unset;
  right: 0;
  bottom: 40px; // Place the music player 40px higher for mobile devices
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-width: 300px;
  margin: auto;
  z-index: 1000;

  // Media query for smaller screens
  @media ${({ theme }) => theme.breakpoints.md} {
    right: 0;
    left: unset;
    bottom: 0; // Default alignment at the bottom for larger screens
  }
`;

export const Component = styled.div`
  position: absolute;
  width: 30rem;
`;

export const MusicCover = styled.img`
  border-radius: 10%;
  position: absolue;
`;

export const PlayButton = styled.button`
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
`;


export const ButtonImg = styled.img`
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
`;

export const SubTitle = styled.p`
  margin-top: -1em;
  color: #4f4f4f;
`;

export const Time = styled.div`
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  color: #828282;
  font-size: smaller;
`;

export const Timeline = styled.div`
  width: 80%;
  background-color: #27ae60;
`;

export const RangeInput = styled.input.attrs({ type: 'range' })`
  background-color: #27ae60;
`;

// Container for the music player
export const PlayerContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 260px; // Adjust the width as necessary
`;

// Component for the album cover
export const AlbumArt = styled.img`
  width: 50px; // Adjust the size as necessary
  height: 50px;
  border-radius: 10px;
`;

// Component for the track information
export const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
`;

// Styled component for the track title
export const TrackTitle = styled.span`
  font-size: 14px; // Adjust the size as necessary
  color: #000;
  
`;

// Styled component for the track duration
export const TrackDuration = styled.span`
  font-size: 12px; // Adjust the size as necessary
  color: #666;
`;

// Component for the player controls
export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

// Individual button in the player controls
export const PlayPauseButton = styled.button`
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  max-height: 32px;
`;
