import { useEffect, useState } from "react";
import useSound from "use-sound"; // for handling the sound
import {Fine} from "@assets/music"; // importing the music

import { Play, Stop } from "@styled-icons/octicons";
import {
  App, PlayerContainer, AlbumArt, TrackInfo, TrackTitle, TrackDuration, Controls, PlayPauseButton, ButtonImg
} from './styled'; // Update the path accordingly


const Player = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [position, setPosition] = useState(0)
  const [play, { pause, duration, sound }] = useSound(Fine, {loop: true, autoplay: true});

  const formatDuration = (seconds: number): string => {
    const minutes: number = Math.floor(seconds / 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds % 60}`;
  };

  let playtime = sound?.seek()

  setInterval(() => {
    updateProgress(); 
  }, 1000);

  function updateProgress() {
    if (sound?.playing()) {
      playtime = sound?.seek()
      setPosition(Math.round(playtime))
    }
  }

  useEffect(() => {
    // Play or pause the sound when `isPlaying` changes
    if (isPlaying) {
      sound?.play();
      setInterval(() => {
        updateProgress(); 
      }, 1000);
    } else {
      sound?.pause();
      setInterval(() => {
        updateProgress(); 
      }, 1000);
    }
  }, [isPlaying, sound]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  
  return (
      <App>
          {/* <MusicPlayer trackTitle="Fine" duration={"4"} albumArtUrl="images/fine.jpeg" /> */}
          <PlayerContainer>
            <AlbumArt src={"images/fine.jpeg"} />
            <TrackInfo>
              <TrackTitle>Fine</TrackTitle>
              <TrackDuration>{formatDuration(position)}</TrackDuration>
            </TrackInfo>
            <Controls>
              {/* Insert icons or text for play/pause buttons here */}
              { isPlaying ?
                <PlayPauseButton onClick={togglePlayPause}><ButtonImg src={"images/pause.svg"}/></PlayPauseButton>
                :
                <PlayPauseButton onClick={togglePlayPause}><ButtonImg src={"images/play.svg"} /></PlayPauseButton>
              }
            </Controls>
          </PlayerContainer>
      </App>
  );
};

export default Player;
