// Finds the closest index in the Array that the number provided is greater than
export function getClosestIndex(arr: Array<number>, num: number) {
  return Array.isArray(arr)
    ? arr
        .concat(num)
        .sort((a, b) => a - b)
        .indexOf(num)
    : 0;
}

export function truncatedAddress(address: string) {
  return `${address?.substr(0, 4)}...${address?.substr(-4)}`;
}

export function truncatedBalance(balance: string) {
  return Math.ceil(parseFloat(balance) * 100) / 100;
}

export function unixEpochToDateTime(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);

  // Using the browser's default locale
  const formattedYear = date.toLocaleString([], { year: "numeric" });
  const formattedMonth = date.toLocaleString([], { month: "2-digit" });
  const formattedDay = date.toLocaleString([], { day: "2-digit" });
  const formattedHour = date.toLocaleString([], { hour: "numeric", minute: "2-digit" });
  // const formattedMin = date.toLocaleString([], {minute: '2-digit'})

  // Get a DateTimeFormat object for the user's current culture (via undefined)
  // Ask specifically for the long-form of the time zone name in the options
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "short" });

  // Format the date to parts, and pull out the value of the time zone name
  const brokenPart = dtf
    ?.formatToParts(date)
    .find((part) => part.type === "timeZoneName");
  const result = brokenPart?.value;

  return `${formattedYear}.${formattedMonth}.${formattedDay} ${formattedHour} ${result}`;
}

export function unixEpochToDate(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);

  // Using the browser's default locale
  const formattedYear = date.toLocaleString([], { year: "numeric" });
  const formattedMonth = date.toLocaleString([], { month: "2-digit" });
  const formattedDay = date.toLocaleString([], { day: "2-digit" });

  return `${formattedYear}.${formattedMonth}.${formattedDay}`;
}

export function unixEpochToTime(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000);

  // Using the browser's default locale
  const formattedDay = date.toLocaleString([], { day: "2-digit" });
  const formattedHour = date.toLocaleString([], { hour: "2-digit" });
  // const formattedMin = date.toLocaleString([], {minute: '2-digit'})

  return `${formattedDay}:${formattedHour} ${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;
}
