import { useState } from "react";
import { ThemeProvider } from "styled-components";

import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { ArrowLeft } from "@styled-icons/octicons";

import Mint from "@pages/Mint";
import ContractProvider from "@context/ContractProvider";
import { Footer } from "@components/Footer";
import {
  PageWrapper,
  BackButton,
  GraphicOverlay,
  Crest,
  LogoContainer,
} from "./styled";

import getTheme from "@theme/theme";
import GlobalStyle from "./GlobalStyles";
import { Player } from "./components/Player";

const { chains, publicClient } = configureChains(
  [process.env.REACT_APP_IS_MAINNET === "true" ? mainnet : sepolia],
  [
    infuraProvider({ apiKey: process.env.REACT_APP_INFURAKEY || "" }),
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMYKEY || "" }),
    publicProvider(),
  ]
);

// projectId from https://cloud.walletconnect.com/
const { connectors } = getDefaultWallets({
  appName: "Rosentica Mint Site",
  projectId: "72e013f8866c022e01a17ab3c9f22f65",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors,
  publicClient,
});

function App() {
  const [theme, setTheme] = useState(getTheme());

  const changeTheme = (theme: "dark" | "light") => {
    const newTheme = getTheme(theme);
    setTheme(newTheme);
  };

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <PageWrapper>
            <GraphicOverlay />
            <a href="https://rosentica.jp/">
              <BackButton onClick={() => {}}>
                <ArrowLeft /> <span>Back to Homepage</span>
              </BackButton>
            </a>
            <LogoContainer href="">
              <img src="images/logo.webp" alt="Rosentica logo" />
            </LogoContainer>
            <Crest />
            <ContractProvider>
              <Mint changeTheme={changeTheme} />
            </ContractProvider>
            <Footer />
            <Player />
          </PageWrapper>
        </ThemeProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
