import styled from "styled-components";

import { baseColors } from "@theme/colors";
import { LinkButton } from "@components/Buttons";

const LinkButtonBase = styled(LinkButton)`
  transition: color 2s linear, background 2s linear, opacity 2s linear;

  &:hover {
    transition: color 0s linear, background 0s linear, opacity 2s linear;
  }
`;

export const BackButton = styled(LinkButtonBase)`
  position: fixed;

  span {
    opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
    transition: opacity 2s linear;
  }
`;

export const CenterButtonContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 275px;
`;

export const ConnectWalletButton = styled(LinkButtonBase)`
  color: ${baseColors.white};
  font-size: 1.33em;
  background-color: ${baseColors.darkBlue};
  padding: ${({ theme }) => theme.spacing.x2} ${({ theme }) => theme.spacing.x4};
  opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
  width: 100%;

  svg {
    fill: ${baseColors.white};
    height: 1.5em;
    margin: 0;
    margin-left: 8px;
    transition: fill 2s linear;
  }

  &:hover {
    svg {
      transition: fill 0s linear;
    }
  }
`;

export const WLCheckReminder = styled.div`
  font-weight: 700;
  text-align: center;
  padding: 16px 0 16px 0;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
`;

export const MintingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 960px;
  height: 65%;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 1 : 0)};
  transition: ${({ theme }) =>
    theme.mode === "dark" ? "opacity 2s linear" : "opacity 0s linear"};
  transition-delay: ${({ theme }) => (theme.mode === "dark" ? "2.3s" : "0s")};
`;

export const StatusBox = styled.div`
  z-index: 2;
  position: fixed;
  top: 15%;
  left: 0;
  width: 100%;
  max-width: 960px;
  height: 75%;
  min-height: 425px;
  background: url("images/badge_origin.png");
  background-repeat: no-repeat;
  background-color: #1c1e20;
  background-size: contain;
  transition: opacity 2s linear;
  border-radius: 12px;
  overflow: auto;

  @keyframes fadeAnimation {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  h3 {
    font-weight: 700;
    margin-bottom: 8px;

    span.live {
      background: #f00;
      font-size: 0.67em;
      font-weight: 900;
      padding: 2px 5px 2px 5px;
      border-radius: 3px;
      animation: fadeAnimation 3s ease-in-out infinite;
    }

    div.enddate {
      color: #858a8e;
      font-size: 0.75em;
    }
  }

  section {
    width: 100%;
  }
`;

export const WalletStatus = styled.div`
  height: 0.75em;
  width: 100%;
  color: #858a8e;
  position: absolute;
  bottom: -1.5em;
  font-size: 0.75em;
  text-align: center;

  span {
    font-size: 1em;
    color: #d2d4d7;
  }

  button {
    font-size: 1em;
    color: #858a8e;
    text-decoration: underline;
    transition: color 2s linear;

    &:hover {
      transition: color 0s linear;
    }
  }
`;
