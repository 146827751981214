import {
  TimelineContainer,
  TimelineLine,
  TimelineNodeAnchor,
  TimelineNodeContainer,
  TimelineNode,
  Butterfly,
} from "./styled";

import { unixEpochToDateTime } from "@utils/utils";

const MintTimeline = ({ currPhase, phaseStartTimes }) => {
  return (
    <TimelineContainer>
      <TimelineLine />
      <TimelineNodeAnchor>
        <TimelineNodeContainer
          style={{
            transform: `translateX(${-currPhase.phase * 20}%)`,
          }}
        >
          <TimelineNode>
            <p>WL Check</p>
            <p>01.08 ~ 01.29</p>
            <img src="images/timelinenode.png" alt="Timeline node" />
          </TimelineNode>
          <TimelineNode>
            <p>Traveler's List</p>
            <p>{unixEpochToDateTime(Number(phaseStartTimes[0]))}</p>
            <img src="images/timelinenode.png" alt="Timeline node" />
          </TimelineNode>
          <TimelineNode>
            <p>Citizen's Claim</p>
            <p>{unixEpochToDateTime(Number(phaseStartTimes[1]))}</p>
            <img src="images/timelinenode.png" alt="Timeline node" />
          </TimelineNode>
          <TimelineNode>
            <p>Public Recruitment</p>
            <p>
              {unixEpochToDateTime(Number(phaseStartTimes[2]))} ~{" "}
              {unixEpochToDateTime(Number(phaseStartTimes[3]))}
            </p>
            <img src="images/timelinenode.png" alt="Timeline node" />
          </TimelineNode>
          <TimelineNode></TimelineNode>
          <TimelineNode></TimelineNode>
        </TimelineNodeContainer>
      </TimelineNodeAnchor>
      <Butterfly />
    </TimelineContainer>
  );
};

export default MintTimeline;
