import styled from "styled-components";

import {
  ColorType,
  AlignType,
  SeparatorType,
  ContainerProps,
  ContainerContentProps,
  ContainerSeparatorProps,
  HorizontalStackProps,
  EqualHStackProps,
  VerticalStackProps,
  DeviceShowType,
} from "./types";

export const Container = styled.div<
  ContainerProps & { $colorType?: ColorType }
>(({ $colorType: type = ColorType.PRIMARY, theme }) => {
  const breakpointMd = `@media ${theme.breakpoints.md}`;

  return {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: `${theme.spacing.x1} ${theme.spacing.x3}`,
    overflow: "hidden",
    [breakpointMd]: {
      padding: `${theme.spacing.x10} ${theme.spacing.x10} ${theme.spacing.x5} ${theme.spacing.x10}`,
    },
    ...getColorTypeStyle(type, theme),
  };
});

export const ContainerContent = styled.div<
  ContainerContentProps & {
    $alignType?: AlignType;
    $deviceShowType?: DeviceShowType;
  }
>`
  display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
    $deviceShowType === DeviceShowType.DESKTOPONLY ? "none" : "block"};
  width: 100%;
  z-index: 1;
  max-width: 1080px;
  text-align: ${({ $alignType = AlignType.LEFT }) => $alignType};

  @media ${({ theme }) => theme.breakpoints.md} {
    display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
      $deviceShowType === DeviceShowType.MOBILEONLY ? "none" : "block"};
  }
`;

export const ContainerFlexContent = styled.div<
  ContainerContentProps & {
    $alignType?: AlignType;
    $deviceShowType?: DeviceShowType;
  }
>`
  display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
    $deviceShowType === DeviceShowType.DESKTOPONLY ? "none" : "flex"};
  z-index: 1;
  max-width: 1080px;
  align-items: center;
  justify-content: ${({ $alignType = AlignType.LEFT }) =>
    $alignType === AlignType.LEFT
      ? "flex-start"
      : $alignType === AlignType.RIGHT
      ? "flex-end"
      : "center"};

  @media ${({ theme }) => theme.breakpoints.md} {
    display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
      $deviceShowType === DeviceShowType.MOBILEONLY ? "none" : "flex"};
  }
`;

export const ContainerSeparator = styled.div<
  ContainerSeparatorProps & { $separatorType?: SeparatorType }
>(({ $separatorType = SeparatorType.FULL, theme }) => ({
  position: "absolute",
  bottom: 0,
  ...getSeparatorTypeStyle($separatorType, theme),
}));

export const HorizontalStack = styled.div<
  HorizontalStackProps & { $isWrap?: boolean; $noMobileCollapse?: boolean }
>`
  width: 100%;
  gap: ${({ theme }) => theme.spacing.x2};
  display: flex;
  flex-wrap: ${({ $noMobileCollapse = false }) => {
    if ($noMobileCollapse) {
      return "nowrap;";
    } else {
      return "wrap;";
    }
  }}
  @media ${({ theme }) => theme.breakpoints.md} {
    flex-wrap: ${({ $isWrap = false }) => ($isWrap ? `wrap` : `nowrap`)};
    }
  }
`;

export const EqualHStack = styled(HorizontalStack)<
  EqualHStackProps & { $noMobileCollapse?: boolean }
>`
  justify-content: center;

  > * {
    width: ${({ theme, columns = 3, $noMobileCollapse = false }) => {
      if ($noMobileCollapse) {
        const numGaps = columns - 1;
        const contentWidth = `100% - (${theme.spacing.x2} * ${numGaps})`;
        return `calc((${contentWidth}) / ${columns})`;
      } else {
        return "100%;";
      }
    }}
  }
  @media ${({ theme }) => theme.breakpoints.md} {
  > * {
    width: ${({ theme, columns = 3 }) => {
      const halfColumns = Math.ceil(columns / 2);
      const numGaps = halfColumns - 1;
      const contentWidth = `100% - (${theme.spacing.x2} * ${numGaps})`;
      return `calc((${contentWidth}) / ${halfColumns})`;
    }}
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    > * {
      width: ${({ theme, columns = 3 }) => {
        const numGaps = columns - 1;
        const contentWidth = `100% - (${theme.spacing.x2} * ${numGaps})`;
        return `calc((${contentWidth}) / ${columns})`;
      }}
    }
`;

export const VerticalStack = styled.div<
  VerticalStackProps & {
    $alignType?: AlignType;
  }
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $alignType = AlignType.LEFT }) =>
    $alignType === AlignType.LEFT
      ? "flex-start"
      : $alignType === AlignType.RIGHT
      ? "flex-end"
      : "center"};

  @media ${({ theme }) => theme.breakpoints.md} {
    align-items: ${({ align = "center" }) => align};
  }
`;

export const Spacer = styled.div<{ $deviceShowType?: DeviceShowType }>`
  width: 100%;
  height: ${({ theme }) => theme.sizes.navbarHeight};
  display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
    $deviceShowType === DeviceShowType.DESKTOPONLY ? "none" : "flex"};

  @media ${({ theme }) => theme.breakpoints.md} {
    display: ${({ $deviceShowType = DeviceShowType.ALWAYS }) =>
      $deviceShowType === DeviceShowType.MOBILEONLY ? "none" : "flex"};
  }
`;

function getColorTypeStyle(colorType: ColorType, theme: any): Object {
  switch (colorType) {
    case ColorType.PRIMARY:
      return {
        backgroundColor: theme.colors.bgColor,
        color: theme.colors.fgColor,
      };
    case ColorType.SECONDARY:
      return {
        backgroundColor: theme.colors.secondaryBgColor,
        color: theme.colors.secondaryFgColor,
      };
  }
}

function getSeparatorTypeStyle(
  separatorType: SeparatorType,
  theme: any
): Object {
  switch (separatorType) {
    case SeparatorType.HERO:
      const breakpointMd: string = `@media ${theme.breakpoints.md}`;
      return {
        background: theme.colors.bgColor,
        height: "15px",
        width: "100%",
        borderRadius: "15px 15px 0 0",
        [breakpointMd]: {
          background: `linear-gradient(to bottom, rgba(255,255,255,0) 50%, ${theme.colors.bgColor} 100%)`,
          height: "150px",
          borderRadius: "0",
        },
      };
    case SeparatorType.FULL:
      return {
        background: theme.colors.accent,
        height: "5px",
        width: "100%",
      };
    case SeparatorType.PARTIAL:
      return {
        background: theme.colors.accent,
        height: "3px",
        width: "33%",
      };
    default:
      return {};
  }
}
