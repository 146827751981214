import styled from "styled-components";
import { VerticalStack } from "@components/Layout";

export const MintStage = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
`;

export const MintAllowance = styled.div`
  display: flex;
  height: 38px;
  width: 64px;
  align-items: center;
  justify-content: center;
  border: 1px solid #797c80;
  border-radius: 8px;
`;

export const MintingVStack = styled(VerticalStack)`
  width: 100%;
  gap: ${({ theme }) => theme.spacing.x3};
  padding: ${({ theme }) =>
    `${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1}`};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: ${({ theme }) =>
      `${theme.spacing.x6} ${theme.spacing.x6} ${theme.spacing.x6} ${theme.spacing.x3}`};
  }
`;

export const MintingButterfly = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  img {
    height: 100px;
    margin: 0 0 25px 0;
  }

  h1 {
    font-family: "LT Remark", sans-serif;
    font-size: 23px;
    line-height: 27px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  div {
    color: #858a8e;
    font-size: 0.67em;
  }
`;
