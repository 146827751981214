import styled from "styled-components";
import { LinkProps, Type } from "./types";

export const StyledLink = styled.a<LinkProps & { as?: any; $linkType: Type }>(
  ({ $linkType: type = Type.PRIMARY, theme, disabled }) => ({
    color: disabled ? theme.colors.primaryDisabled : theme.colors.fgColor,
    textDecoration: "none",
    display: "inline-flex",
    alignItems: "center",
    margin: "auto",
    justifyContent: "center",
    cursor: disabled ? `url(/images/kiwiBonk.png), auto` : "pointer",
    verticalAlign: "middle",
    ...(disabled
      ? {}
      : {
          "&:hover, &:active": {
            color: theme.colors.primary,
            svg: {
              fill: theme.colors.primary,
            },
          },
        }),
    ...getKindStyle(type, theme),
  })
);

function getKindStyle(type: Type, theme: any): any {
  switch (type) {
    case Type.ICON:
      return {
        svg: {
          height: 20,
          width: 20,
          "&:hover": {
            color: "blue",
          },
          "&:active": {
            color: "blue",
          },
        },
      };
    default:
      return {
        padding: `${theme.spacing.x4} ${theme.spacing.x3}`,
      };
  }
}
