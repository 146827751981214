import styled from "styled-components";

export const NumberPickerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  width: 44px;
  height: 44px;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: #d2d4d7;
  border: none;
  border-radius: 50px;
  padding: 5px 10px;
  cursor: pointer;
`;

export const InputField = styled.input`
  width: 50px;
  text-align: center;
  background: transparent;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
    height: 44px;
    min-width: 85px;
    border: 1px solid #797c80;
    border-radius: 50px;
    margin: 0 5px;
  }
`;
