import Countdown from "react-countdown";
import { CountdownContainer } from "./styled";

import { DateTimeCountdownProps, CountdownProps } from "./types";

const DateTimeCountdown = ({
  className,
  children,
  epochTime = 0,
  ...props
}: DateTimeCountdownProps) => {
  const eventStartTime = new Date(epochTime * 1000);

  const Completionist = () => <></>;

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownProps) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <CountdownContainer>
          <span>{days.toString().padStart(2, "0")}d</span>
          <span>{hours.toString().padStart(2, "0")}h</span>
          <span>{minutes.toString().padStart(2, "0")}m</span>
          <span>{seconds.toString().padStart(2, "0")}s</span>
          <span>left</span>
        </CountdownContainer>
      );
    }
  };

  if (!eventStartTime)
    return <div>No event start time defined or provider required.</div>;

  return (
    <div>
      <Countdown date={eventStartTime} renderer={renderer}></Countdown>
    </div>
  );
};

export default DateTimeCountdown;
