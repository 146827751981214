import { StyledButton, StyledButtonText } from "./styled";
import { ButtonProps, Type } from "./types";

const ButtonBase = ({
  className,
  onClick,
  text,
  buttonType = Type.PRIMARY,
  children,
  disabled,
  isLoading,
}: ButtonProps) => (
  <StyledButton
    className={className}
    onClick={onClick}
    $buttonType={buttonType}
    disabled={disabled}
  >
    {isLoading ? "Loading..." : children}
  </StyledButton>
);

export default ButtonBase;
