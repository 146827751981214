import styled from "styled-components";

import { Link } from "@components/Links";
import { LinkButton } from "@components/Buttons";

const LinkButtonBase = styled(LinkButton)`
  transition: color 2s linear, background 2s linear, opacity 2s linear;

  &:hover {
    transition: color 0s linear, background 0s linear, opacity 2s linear;
  }
`;

export const PageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.bgColor};
  background-size: cover;
  background-position: left;
  height: 100vh;
  min-height: 500px;
  width: 100vw;
  z-index: -1;
  padding: 20px;
  transition: background 2s linear;
`;

export const GraphicOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: url("images/mintbg.png");
  background-size: cover;
  background-position: left;
  height: 100%;
  width: 100vw;
  z-index: -1;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 0.25)};
  transition: opacity 2s linear;
`;

export const Crest = styled.div`
  position: absolute;
  top: 50%;
  left: 50vw;
  transform: translate(-50%, -50%);
  background: url("images/crest.png");
  height: 191px;
  width: 195px;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 1 : 0)};
  transition: opacity 2s linear;
`;

export const LogoContainer = styled(Link)`
  position: fixed;
  top: 32.5px;
  left: 50vw;
  transform: translate(-50%, -50%);

  padding: 0;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 1 : 0)};
  transition: opacity 2s linear;

  img {
    height: 39px;
    width: 100px;
  }
`;

export const BackButton = styled(LinkButtonBase)`
  position: fixed;

  span {
    opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
    transition: opacity 2s linear;
  }
`;
