import styled from "styled-components";

export const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

  a {
    margin: 10px;
    flex-direction: column;
    transition: color 2s linear, background 2s linear, opacity 2s linear;

    span {
      margin-top: ${({ theme }) => theme.spacing.x1};
      font-size: 0.75em;
      font-weight: 100;
      opacity: 0;
      transition: color 2s linear, background 2s linear;

      @media ${({ theme }) => theme.breakpoints.lg} {
        opacity: 1;
      }
    }

    svg {
      transition: fill 2s linear, background 2s linear;
    }

    @media ${({ theme }) => theme.breakpoints.lg} {
      min-width: 50px;
      margin: 20px;
    }

    &:hover {
      span {
        color: ${({ theme }) => theme.colors.accent};
        transition: color 0s linear, background 0s linear;
      }

      svg {
        fill: ${({ theme }) => theme.colors.accent};
        transition: fill 0s linear, background 0s linear;
      }
    }
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    height: 80px;
  }
`;
