import { useConnectModal } from "@rainbow-me/rainbowkit";

import { CreditCard } from "@styled-icons/octicons";
import { Countdown } from "@components/Countdown";

import {
  CenterButtonContainer,
  ConnectWalletButton,
  WLCheckReminder,
} from "./styled";

const Connect = ({ currPhase }: { currPhase: number }) => {
  // Rainbowkit and Wagmi.sh stuff
  const { openConnectModal } = useConnectModal();

  return (
    <CenterButtonContainer>
      <Countdown epochTime={1706623200} />
      {openConnectModal && (
        <ConnectWalletButton onClick={openConnectModal}>
          {currPhase === 0 ? "Check my WL" : "Connect Wallet"}
          <CreditCard />
        </ConnectWalletButton>
      )}
      {currPhase === 0 && (
        <div>
          <WLCheckReminder>
            Please check your WL in advance!
            <br />
          </WLCheckReminder>
          <a
            style={{margin: "auto", display: "block", textAlign: "center"}}
            href="https://twitter.com/Rosentica"
            target="_blank"
            rel="noreferrer"
          >
            Mint Schedule
          </a>
        </div>
      )}
    </CenterButtonContainer>
  );
};

export default Connect;
