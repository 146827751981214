import * as colorThemes from "./colors";
import {
  size as breakpointSizes,
  breakpoints,
  maxBreakpoints,
} from "./breakpoints";

const baseTheme = {
  fontWeights: {
    normal: 400,
  },
  fontSizes: {
    xxsmall: "12px",
    xsmall: "14px",
    small: "16px",
    normal: "20px",
    large: "24px",
    xlarge: "32px",
    headline: "64px",
    title: "32px",
    subtitle: "24px",
  },
  spacing: {
    x1: "8px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x5: "40px",
    x6: "48px",
    x8: "64px",
    x10: "80px",
    x12: "96px",
  },
  radii: {
    x1: "8px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x8: "64px",
    x10: "80px",
  },
  sizes: {
    navbarHeight: "80px",
    mobileNavbarHeight: "60px",
  },
  breakpointSizes,
  breakpoints,
  maxBreakpoints,
};

const getTheme = (theme: "dark" | "light" = "light") => {
  return {
    ...baseTheme,
    colors: colorThemes[`${theme}ModeColors`],
    mode: `${theme}`,
  };
};

export default getTheme;
