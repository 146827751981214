import styled from "styled-components";

import { baseColors } from "@theme/colors";
import { LinkButton } from "@components/Buttons";

const LinkButtonBase = styled(LinkButton)`
  transition: color 2s linear, background 2s linear, opacity 2s linear;

  &:hover {
    transition: color 0s linear, background 0s linear, opacity 2s linear;
  }
`;

export const BackButton = styled(LinkButtonBase)`
  position: fixed;

  span {
    opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
    transition: opacity 2s linear;
  }
`;

export const CenterButtonContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 275px;
`;

export const ConnectWalletButton = styled(LinkButtonBase)`
  color: ${baseColors.white};
  font-size: 1.33em;
  background-color: ${baseColors.darkBlue};
  padding: ${({ theme }) => theme.spacing.x2} ${({ theme }) => theme.spacing.x4};
  opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
  width: 100%;

  svg {
    fill: ${baseColors.white};
    height: 1.5em;
    margin: 0;
    margin-left: 8px;
    transition: fill 2s linear;
  }

  &:hover {
    svg {
      transition: fill 0s linear;
    }
  }
`;

export const WLCheckReminder = styled.div`
  font-weight: 700;
  text-align: center;
  padding: 16px 0 16px 0;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
`;

export const MintingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 960px;
  height: 65%;
  opacity: ${({ theme }) => (theme.mode === "dark" ? 1 : 0)};
  transition: ${({ theme }) =>
    theme.mode === "dark" ? "opacity 2s linear" : "opacity 0s linear"};
  transition-delay: ${({ theme }) => (theme.mode === "dark" ? "2.3s" : "0s")};
`;

export const TimelineContainer = styled.div`
  position: relative;
  top: -25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  height: 150px;
  margin: auto;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 25%; /* Adjust the width of the fade effect */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #222528);
    pointer-events: none;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 25%; /* Adjust the width of the fade effect */
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #222528);
    pointer-events: none;
    z-index: 1;
  }
`;

export const TimelineLine = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background: #ffffff;
  opacity: 0.5;
`;

export const TimelineNodeAnchor = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 200px;
`;

export const TimelineNodeContainer = styled.div`
  position: absolute;
  transform: translateX(0%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150%;
  height: 200px;
  transition: transform 2s linear;
`;

export const TimelineNode = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 0px;
  height: 32px;
  background: url("images/timelinenode.png");
  background-repeat: no-repeat;
  background-position: center;

  p {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.9em;
    width: 150px;
    text-align: center;
    position: relative;
    left: -75px;
    top: -45px;
  }

  p:first-child {
    color: #858a8e;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;
  }

  p:nth-child(2) {
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: 700;
  }

  img {
    position: absolute;
    left: -12px;
    top: 4px;
    height: 24px;
    width: 24px;
  }
`;

export const Butterfly = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  background: url("images/butterfly.png");
`;

export const StatusBox = styled.div`
  z-index: 2;
  position: fixed;
  top: 15%;
  left: 0;
  width: 100%;
  max-width: 960px;
  height: 75%;
  min-height: 425px;
  background: url("images/badge_origin.png");
  background-repeat: no-repeat;
  background-color: #1c1e20;
  background-size: contain;
  transition: opacity 2s linear;
  border-radius: 12px;
  overflow: auto;

  @keyframes fadeAnimation {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  h3 {
    font-weight: 700;
    margin-bottom: 8px;

    span.live {
      background: #f00;
      font-size: 0.67em;
      font-weight: 900;
      padding: 2px 5px 2px 5px;
      border-radius: 3px;
      animation: fadeAnimation 3s ease-in-out infinite;
    }

    div.enddate {
      color: #858a8e;
      font-size: 0.75em;
    }
  }

  section {
    width: 100%;
  }
`;

export const WalletStatus = styled.div`
  height: 0.75em;
  width: 100%;
  color: #858a8e;
  position: absolute;
  bottom: -1.5em;
  font-size: 0.75em;
  text-align: center;

  span {
    font-size: 1em;
    color: #d2d4d7;
  }

  button {
    font-size: 1em;
    color: #858a8e;
    text-decoration: underline;
    transition: color 2s linear;

    &:hover {
      transition: color 0s linear;
    }
  }
`;
