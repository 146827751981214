import styled from "styled-components";

export const MintedCount = styled.div`
  color: #858a8e;
  font-size: 1em;
  margin-top: -5px;
  margin-right: 5px;

  strong {
  }
`;
