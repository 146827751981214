import styled from "styled-components";
import { VerticalStack } from "@components/Layout";

export const WLCheckTitle = styled.div`
  color: #b0b0b0;
  padding: 32px 0 24px 0;

  strong {
    font-weight: 700;
  }
`;

export const WLCheckBadge = styled.div<{
  imgurl: string;
  have?: boolean;
}>`
  background: url(${(props) => props.imgurl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 220px;
  width: 200px;
  opacity: ${(props) => (props.have ? "1" : "0.25")};
`;

export const WLCheckMintableAmount = styled.div`
  padding: 16px 0 24px 0;

  p {
    text-align: center;
    padding: 4px;

    strong {
      background: #000;
      font-weight: 700;
      padding: 4px 8px 4px 8px;
      border-radius: 7px;
      margin: 4px;
    }
  }

  p:first-child {
    font-weight: 700;
  }
`;
