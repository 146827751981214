import { MintClosedStage, MintingButterfly } from "./styled";

const MintClosed = () => {
  return (
    <MintClosedStage>
      <MintingButterfly>
        <img src="images/mintclosed.png" alt="Mint closed" />
        <h1>Mint phase is over.</h1>
        <div>Thanks for your support</div>
      </MintingButterfly>
    </MintClosedStage>
  );
};

export default MintClosed;
