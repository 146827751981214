export enum ColorType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum AlignType {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum DeviceShowType {
  ALWAYS = "always",
  MOBILEONLY = "mobileOnly",
  DESKTOPONLY = "desktopOnly",
}

export enum SeparatorType {
  HERO = "hero",
  FULL = "full",
  PARTIAL = "partial",
}

export type ContainerProps = {
  colorType?: ColorType;
};

export type ContainerContentProps = {
  alignType?: AlignType;
  deviceShowType?: DeviceShowType;
};

export type ContainerSeparatorProps = {
  separatorType?: SeparatorType;
};

export type HorizontalStackProps = {
  isWrap?: boolean;
  noMobileCollapse?: boolean;
};

export type EqualHStackProps = {
  columns?: number;
  noMobileCollapse?: boolean;
};

export type VerticalStackProps = {
  alignType?: AlignType;
  align?: string;
  spacing?: number;
};
