import styled from "styled-components";

export const MintClosedStage = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const MintingButterfly = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  img {
    height: 100px;
    margin: 0 0 25px 0;
  }

  h1 {
    font-family: "LT Remark", sans-serif;
    font-size: 23px;
    line-height: 27px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  div {
    color: #858a8e;
    font-size: 0.67em;
  }
`;
