import { useContext } from "react";

import { IMintProgressBar } from "./types";

import { ProgressBar } from "@components/ProgressBar";
import { ContractContext, IContractContext } from "@context/ContractProvider";
import { MintedCount } from "./styled";

const MintProgressBar = ({
  contractAddress,
  abi,
  setMintedAmount,
  mintedAmount,
  canMint,
}: IMintProgressBar) => {
  const { totalSupply, maxSupply } =
    useContext<IContractContext>(ContractContext);

  if (totalSupply !== mintedAmount) {
    setMintedAmount(totalSupply || 0);
  }

  return (
    <ProgressBar progress={(totalSupply! / maxSupply) * 100}>
      <MintedCount>
        <strong>{totalSupply}</strong>/{maxSupply} minted
      </MintedCount>
    </ProgressBar>
  );
};

export default MintProgressBar;
