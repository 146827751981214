import styled from "styled-components";

export const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ProgressBarBar = styled.div`
  width: 100%;
  position: relative;
  height: ${({ theme }) => theme.fontSizes.xsmall};
  background-image: linear-gradient(
    135deg,
    #797c80 25%,
    #868a8e 25%,
    #868a8e 50%,
    #797c80 50%,
    #797c80 75%,
    #868a8e 75%,
    #868a8e 100%
  );
  background-size: 20px 20px;
  border-radius: 50px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div<{ width: number }>`
  position: absolute;
  left: 0;
  height: 100%;
  background-color: #d2d4d7;
  opacity: 1;
  width: ${(props) => `${props.width}%`};
`;

export const ProgressBarText = styled.div`
  position: absolute;
  right: 0;

  color: ${({ theme }) => theme.colors.bgColor};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  line-height: ${({ theme }) => theme.fontSizes.xlarge};
  text-align: center;

  strong {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
    line-height: ${({ theme }) => theme.fontSizes.xlarge};
    font-weight: 1000;
  }
`;
