import { useContext } from "react";

import { useAccount, useDisconnect } from "wagmi";

import { ContractContext, IContractContext } from "@context/ContractProvider";
import { CenterButtonContainer } from "./styled";
import Mint from "./Mint";
import Connect from "./components/Connect";

const PageWrapper = ({
  changeTheme,
}: {
  changeTheme: (theme: "dark" | "light") => void;
}) => {
  const { contractLoaded, currPhase } =
    useContext<IContractContext>(ContractContext);

  const { disconnect } = useDisconnect();
  const { address: connectedAddress } = useAccount({
    onConnect() {
      handleWalletConnect();
    },
    onDisconnect() {
      handleWalletDisconnect();
    },
  });

  const handleWalletConnect = () => {
    changeTheme("dark");
  };

  const handleWalletDisconnect = () => {
    disconnect();
    changeTheme("light");
  };

  if (!contractLoaded) {
    return (
      <CenterButtonContainer>
        Error: Could not query the contract.
      </CenterButtonContainer>
    );
  }

  return connectedAddress ? (
    <Mint
      connectedAddress={connectedAddress.toLowerCase()}
      handleWalletDisconnect={handleWalletDisconnect}
    />
  ) : (
    <div>
      <Connect currPhase={currPhase.phase} />
    </div>
  );
};

export default PageWrapper;
