import styled from "styled-components";
import { Button } from "@components/Buttons";

export const StyledButton = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.colors.accent};
  border: none;
  border-radius: 8px;

  &[disabled] {
    background: #333638;
    color: #d2d4d7;

    &:hover {
      color: #d2d4d7;
    }
  }
`;
