import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'LINE Seed Sans';
    src: url('/fonts/LINE_seed_sans_rg.ttf');
  }

  @font-face {
    font-family: 'FK Grotesk';
    src: url('/fonts/FKGROTESK-REGULAR.ttf');
  }

  @font-face {
    font-family: 'LT Remark';
    src: url('/fonts/LTRemark-May2021.otf');
  }

  * {
      color: ${({ theme }) => theme.colors.primary};
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-family: 'FK Grotesk', sans-serif;
      font-weight: 400;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    
  }
`;

export default GlobalStyle;
