import { ProgressBarProps } from "./types";
import {
  ProgressBarWrapper,
  ProgressBarBar,
  ProgressBarFill,
  ProgressBarText,
} from "./styled";

const ProgressBar = ({ progress, children }: ProgressBarProps) => (
  <ProgressBarWrapper>
    <ProgressBarBar>
      <ProgressBarFill width={progress} />
    </ProgressBarBar>
    <ProgressBarText>{children}</ProgressBarText>
  </ProgressBarWrapper>
);

export default ProgressBar;
