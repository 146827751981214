import { useContext, useState, useEffect } from "react";
import { useContractReads } from "wagmi";

import { ContractContext, IContractContext } from "@context/ContractProvider";
import { ProofItem, IProofs } from "@contracts/proofs/types";
import WaitlistProofs from "@contracts/proofs/WaitlistProofs.json";
import WhitelistProofs from "@contracts/proofs/WhitelistProofs.json";

import { LinkButton } from "@components/Buttons";

import { MintingContainer, StatusBox, WalletStatus } from "./styled";
import WLCheckPhase from "./components/WLCheckPhase";
import MintPhase from "./components/MintPhase";
import MintClosed from "./components/MintClosed";
import MintTimeline from "./components/MintTimeline";

const Mint = ({
  connectedAddress,
  handleWalletDisconnect,
}: {
  connectedAddress: any;
  handleWalletDisconnect: any;
}) => {
  const { contractAddress, phaseStartTimes, abi, publicMintLimit, currPhase } =
    useContext<IContractContext>(ContractContext);

  // @ts-ignore
  const { data: purchasedAmount }: { data: number[] } = useContractReads({
    contracts: [
      {
        address: contractAddress,
        abi,
        functionName: "waitlistMintedAmount",
        args: [connectedAddress],
      },
      {
        address: contractAddress,
        abi,
        functionName: "whitelistMintedAmount",
        args: [connectedAddress],
      },
      {
        address: contractAddress,
        abi,
        functionName: "publicMintedAmount",
        args: [connectedAddress],
      },
    ],
    watch: true,
    select: (data): number[] =>
      data?.map((amount) => Number(amount.result)) || [],
  });

  // For removing the wallet connect button (and whatever else) from DOM once the light->dark transition happens
  const [darkComplete, setDarkComplete] = useState(false);

  // Get wait/whitelist amounts on address change
  const [waitlistAmount, setWaitlistAmount] = useState(0);
  const [whitelistAmount, setWhitelistAmount] = useState(0);
  useEffect(() => {
    const { waitlistProofs, whitelistProofs } = getProofs();
    setWaitlistAmount(waitlistProofs?.amount || 0);
    setWhitelistAmount(whitelistProofs?.amount || 0);
  }, [connectedAddress]);

  const getProofs = () => {
    const waitlistProofs: ProofItem = (WaitlistProofs as IProofs)[
      connectedAddress as keyof IProofs
    ] as ProofItem;
    const whitelistProofs: ProofItem = (WhitelistProofs as IProofs)[
      connectedAddress as keyof IProofs
    ] as ProofItem;

    return {
      waitlistProofs,
      whitelistProofs,
    };
  };

  const getActiveProof = () => {
    const { waitlistProofs, whitelistProofs } = getProofs();
    return currPhase.phase == 1
      ? waitlistProofs
      : currPhase.phase == 2
      ? whitelistProofs
      : [];
  };

  const getMintableMinMax = () => {
    let mintableMax = 0;
    switch (currPhase.phase) {
      case 1:
        mintableMax = waitlistAmount - purchasedAmount?.[0] ?? 0;
        break;
      case 2:
        mintableMax = whitelistAmount - purchasedAmount?.[1] ?? 0;
        break;
      case 3:
        mintableMax = publicMintLimit - purchasedAmount?.[2] ?? 0;
    }
    return {
      mintableMax,
      mintableMin: mintableMax ? 1 : 0,
    };
  };
  useEffect(() => {}, [
    waitlistAmount,
    whitelistAmount,
    currPhase,
    publicMintLimit,
  ]);

  return (
    <MintingContainer>
      {phaseStartTimes ? (
        <MintTimeline phaseStartTimes={phaseStartTimes} currPhase={currPhase} />
      ) : null}
      <StatusBox>
        {currPhase.phase === 0 ? (
          <WLCheckPhase
            phaseStartTimes={phaseStartTimes}
            waitlistAmount={waitlistAmount}
            whitelistAmount={whitelistAmount}
          />
        ) : null}
        {[1, 2, 3].includes(currPhase.phase) ? (
          <MintPhase
            getMintableMinMax={getMintableMinMax}
            contractAddress={contractAddress}
            abi={abi}
            currPhase={currPhase}
            phaseEndTime={phaseStartTimes[currPhase.phase]}
            proof={getActiveProof()}
          />
        ) : null}
        {currPhase.phase === 4 ? <MintClosed /> : null}
      </StatusBox>
      <WalletStatus>
        Connected to <span>{connectedAddress}</span>
        &nbsp;&nbsp;
        <LinkButton onClick={handleWalletDisconnect}>Disconnect</LinkButton>
      </WalletStatus>
    </MintingContainer>
  );
};

export default Mint;
