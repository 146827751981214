import { useState, useEffect } from "react";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import { StyledButton } from "./styled";
import { IMintButton } from "./types";

// const mintPrice = BigInt(Number(prices[currPhase - 1].result));

// await wallet.writeContract({
//   chain: sepolia,
//   abi: RosenticaContract.abi,
//   functionName: "mint",
//   address: contractAddress,
//   account: connectedAddress,
//   value:
//   args: [mint, merkleProof, merkleMaxAmount],
// });

const MintButton = ({
  abi,
  contractAddress,
  disabled,
  numToMint,
  proof,
  currPhase,
  setCurrMintStage,
}: IMintButton) => {
  const args = [numToMint, proof?.proof || [], proof?.amount || 0];
  const initialConfig = {
    address: contractAddress,
    //@ts-ignore
    abi: abi,
    functionName: "mint",
    value: BigInt(currPhase.price! * numToMint),
    enabled: Boolean(numToMint),
    scopeKey: currPhase.name + "",
    args,
  };
  // transaction pending approval
  // transaction approved but pending - isLoading
  // transaction complete - isSuccess
  const [hasBlockUpdated, updateBlockStatus] = useState(false);
  const [soldOut, setSoldOut] = useState(false);

  const {
    config,
    error: prepareError,
    refetch,
  } = usePrepareContractWrite(initialConfig);

  const { data, write } = useContractWrite(config);
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => setSoldOut(false), [currPhase]);

  useEffect(() => {
    if (!prepareError && soldOut) {
      setSoldOut(false);
    } else if (prepareError?.message.toString().match(/sold out/i)) {
      setSoldOut(true);
      updateBlockStatus(false);
    } else if (
      prepareError?.message
        .toString()
        .match(/^Not right ETH sent|Merkle failed|Sale ended/)
    ) {
      setTimeout(refetch, 6000);
      if (!hasBlockUpdated) {
        updateBlockStatus(true);
      }
    } else if (hasBlockUpdated) {
      updateBlockStatus(false);
    }
  }, [prepareError]);

  useEffect(() => {
    if (isLoading) {
      setCurrMintStage(1);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      setCurrMintStage(0);
    }
  }, [isSuccess]);

  const handleClick = () => {
    write!();
  };

  return (
    <StyledButton
      onClick={handleClick}
      disabled={disabled || Boolean(prepareError) || soldOut}
      isLoading={hasBlockUpdated}
    >
      {soldOut
        ? `${numToMint} NFT(s) unavailable for mint`
        : `Mint ${numToMint} NFT(s)`}
    </StyledButton>
  );
};

export default MintButton;
