import { createContext, useEffect, useState } from "react";
import { useContractRead, useContractReads } from "wagmi";

import RosenticaContract from "@contracts/Rosentica.json";
import { getClosestIndex } from "@utils/utils";

export interface IContractContext {
  contractAddress: `0x${string}`;
  phasePrices: number[];
  phaseStartTimes: number[];
  abi: any;
  publicMintLimit: number;
  currPhase: ICurrPhase;
  contractLoaded: boolean;
  totalSupply: number | null;
  maxSupply: number | null;
}

export interface ICurrPhase {
  phase: number;
  startTime?: number;
  endTime?: number;
  price?: number;
  name: string;
}

// Phases
// 0 = WL Check
// 1 = Travelers (waitlist)
// 2 = Citizens (whitelist)
// 3 = Public on the timeline
// 4 = Mint ended
const PHASE_NAMES = [
  "WL CHECK",
  "TRAVELER'S LIST",
  "CITIZEN'S CLAIM",
  "PUBLIC MINT",
  "MINT ENDED",
];
const contractAddress: `0x${string}` = process.env
  .REACT_APP_CONTRACT_ADDRESS as `0x${string}`;

export const ContractContext = createContext<IContractContext>({
  contractAddress,
  abi: RosenticaContract.abi,
  contractLoaded: false,
  phasePrices: [],
  phaseStartTimes: [],
  publicMintLimit: 0,
  currPhase: {
    phase: 0,
    name: "",
  },
  totalSupply: null,
  maxSupply: null,
});

const getTime = () => Number(new Date().getTime() / 1000);

const ContractProvider = ({ children }: any) => {
  // Multicall for to read in all the al, wl, and public mint times
  // Needs ts-ignore on account of Typescript don't support importing the ABI as a const :(
  // https://wagmi.sh/react/typescript

  //@ts-ignore
  const { data: supply }: { data: number[]; isSuccess: boolean } =
    useContractReads({
      contracts: [
        {
          address: contractAddress,
          //@ts-ignore
          abi: RosenticaContract.abi,
          functionName: "totalSupply",
        },
        {
          address: contractAddress,
          //@ts-ignore
          abi: RosenticaContract.abi,
          functionName: "maxSupply",
        },
      ],
      //@ts-ignore
      select: (data): number[] =>
        data?.map((phase) => Number(phase.result)) || [],
      watch: true,
    });

  const hasSupplyRemaining = supply ? supply[0] !== supply[1] : true;

  //@ts-ignore
  const {
    data: phaseStartTimes,
    isSuccess,
  }: { data: number[]; isSuccess: boolean } = useContractReads({
    contracts: [
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "waitlistSaleStartTime",
      },
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "whitelistSaleStartTime",
      },
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "publicSaleStartTime",
      },
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "publicSaleEndTime",
      },
    ],
    //@ts-ignore
    select: (data): number[] =>
      data?.map((phase) => Number(phase.result)) || [],
    enabled: hasSupplyRemaining,
  });

  // @ts-ignore
  const { data: phasePrices }: { data: number[] } = useContractReads({
    contracts: [
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "waitlistMintPrice",
      },
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "whitelistMintPrice",
      },
      {
        address: contractAddress,
        //@ts-ignore
        abi: RosenticaContract.abi,
        functionName: "publicMintPrice",
      },
    ],
    select: (data): number[] =>
      data?.map((phase) => Number(phase.result)) || [],
    enabled: hasSupplyRemaining,
  });

  // @ts-ignore
  let { data: publicMintLimit }: { data: number } = useContractRead({
    address: contractAddress,
    //@ts-ignore
    abi: RosenticaContract.abi,
    functionName: "publicMintLimit",
    //@ts-ignore
    select: (data): number => Number(data) || 0,
    enabled: hasSupplyRemaining,
  });

  const [currentTime, setTime] = useState<number>(0);
  const [contextState, setContextState] = useState({
    // one time
    contractAddress,
    abi: RosenticaContract.abi,
    contractLoaded: isSuccess,

    // Updated by contract
    phasePrices, // [phase 1 price, phase 2 price, phase 3 price]
    phaseStartTimes, // [phase 1 start, phase 2 start, phase 3 start, phase 4 start]
    publicMintLimit,
    currPhase: hasSupplyRemaining
      ? { phase: 4, name: "" }
      : { phase: 0, name: "" },
  });

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      publicMintLimit,
    }));
  }, [phasePrices]);

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      publicMintLimit,
    }));
    // @ts-ignore
  }, [publicMintLimit]);

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      totalSupply: supply?.[0],
      maxSupply: supply?.[1],
    }));
    // @ts-ignore

    if (!hasSupplyRemaining) {
      setContextState((prevState) => ({
        ...prevState,
        currPhase: {
          phase: 4,
          startTime: phaseStartTimes![4 - 1],
          price: phasePrices?.[4 - 1],
          name: PHASE_NAMES[4],
        },
      }));
    }
  }, [supply]);

  useEffect(() => {
    if (!contextState.contractLoaded) {
      setContextState((prevState) => ({
        ...prevState,
        contractLoaded: true,
      }));
    }
    // @ts-ignore
  }, [isSuccess]);

  const updateTime = (timeout: number) => {
    setTimeout(() => {
      setTime(getTime());
    }, timeout * 1000);
  };

  // First get the phase start times
  // Once we have the phase start time, set the current time
  // Once we have the current time, set the current phase
  useEffect(() => {
    // const [phase0, phase1, phase2, phase3, phase4] = [
    //   1696797800 - 1,
    //   1696797900 - 1,
    //   1696798000 - 1,
    //   1696898000 - 1,
    //   1696898000 + 1,
    // ];
    // const currentTime = phase2;
    updateTime(0);
    // @ts-ignore
    setContextState((prevState) => ({
      ...prevState,
      phaseStartTimes,
    }));
  }, [phaseStartTimes]);

  useEffect(() => {
    const newPhaseIndex = hasSupplyRemaining
      ? getClosestIndex(phaseStartTimes!, currentTime)
      : 4;
    const currPhase = {
      phase: newPhaseIndex,
      startTime: newPhaseIndex && phaseStartTimes![newPhaseIndex - 1],
      price: newPhaseIndex && phasePrices?.[newPhaseIndex - 1],
      name: PHASE_NAMES[newPhaseIndex],
    };
    setContextState((prevState) => ({
      ...prevState,
      currPhase,
    }));

    if (!!contextState.phaseStartTimes) {
      const nextPhaseStartTime = contextState.phaseStartTimes![newPhaseIndex];
      if (nextPhaseStartTime > currPhase.startTime) {
        updateTime(nextPhaseStartTime - currentTime);
      }
    }
  }, [currentTime]);

  return (
    <ContractContext.Provider value={contextState}>
      {children}
    </ContractContext.Provider>
  );
};

export default ContractProvider;
