import styled from "styled-components";
import { VerticalStack } from "@components/Layout";

export const SilhouetteVStack = styled(VerticalStack)`
  margin-left: auto;
  margin-right: auto;
  padding: ${({ theme }) =>
    `${theme.spacing.x3} ${theme.spacing.x1} ${theme.spacing.x1} ${theme.spacing.x1}`};

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: ${({ theme }) =>
      `${theme.spacing.x6} ${theme.spacing.x3} ${theme.spacing.x6} ${theme.spacing.x6}`};
  }
`;

export const RotatingSilhouetteBox = styled.div`
  width: 240px;
  height: 240px;
  background-size: cover;
  animation: rotateImages 30s linear infinite;
  background-image: url("images/silhouettes/silhouette0.png");
  background-color: ${({ theme }) => theme.colors.bgColor};
  border-radius: 12px;

  @keyframes rotateImages {
    0%,
    8% {
      background-image: url("images/silhouettes/silhouette0.png");
    }
    10%,
    18% {
      background-image: url("images/silhouettes/silhouette1.png");
    }
    20%,
    28% {
      background-image: url("images/silhouettes/silhouette2.png");
    }
    30%,
    38% {
      background-image: url("images/silhouettes/silhouette3.png");
    }
    40%,
    48% {
      background-image: url("images/silhouettes/silhouette4.png");
    }
    50%,
    58% {
      background-image: url("images/silhouettes/silhouette5.png");
    }
    60%,
    68% {
      background-image: url("images/silhouettes/silhouette6.png");
    }
    70%,
    78% {
      background-image: url("images/silhouettes/silhouette7.png");
    }
    80%,
    88% {
      background-image: url("images/silhouettes/silhouette8.png");
    }
    90%,
    100% {
      background-image: url("images/silhouettes/silhouette9.png");
    }
  }
`;
