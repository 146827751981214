import styled from "styled-components";

export const Butterfly = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  background: url("images/butterfly.png");
`;

export const TimelineContainer = styled.div`
  position: relative;
  top: -25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  height: 150px;
  margin: auto;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 25%; /* Adjust the width of the fade effect */
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #222528);
    pointer-events: none;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 25%; /* Adjust the width of the fade effect */
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #222528);
    pointer-events: none;
    z-index: 1;
  }
`;

export const TimelineLine = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background: #ffffff;
  opacity: 0.5;
`;

export const TimelineNodeAnchor = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 200px;
`;

export const TimelineNodeContainer = styled.div`
  position: absolute;
  transform: translateX(0%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150%;
  height: 200px;
  transition: transform 2s linear;
`;

export const TimelineNode = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 0px;
  height: 32px;
  background: url("images/timelinenode.png");
  background-repeat: no-repeat;
  background-position: center;

  p {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.9em;
    width: 150px;
    text-align: center;
    position: relative;
    left: -75px;
    top: -45px;
  }

  p:first-child {
    color: #858a8e;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;
  }

  p:nth-child(2) {
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: 700;
  }

  img {
    position: absolute;
    left: -12px;
    top: 4px;
    height: 24px;
    width: 24px;
  }
`;
