import styled from "styled-components";
import { baseColors } from "@theme/colors";

export const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${baseColors.darkBlue};
  background-color: ${baseColors.white};
  padding: ${({ theme }) => theme.spacing.x2} ${({ theme }) => theme.spacing.x4};
  opacity: ${({ theme }) => (theme.mode === "dark" ? 0 : 1)};
  margin-bottom: 16px;
  border: 1px solid ${baseColors.darkBlue};
  padding: 16px 16px;

  span {
    color: ${baseColors.darkBlue};
    font-size: 1.33em;
    width: 20%;
    text-align: center;
  }
`;
