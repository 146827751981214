import { IconLink } from "@components/Links";
import { Discord, Twitter, Opensea } from "@styled-icons/simple-icons";

import { FooterContainer } from "./styled";

const Footer = () => {
  return (
    <FooterContainer>
      <IconLink href="https://discord.gg/rosentica">
        <Discord />
        <span>Discord</span>
      </IconLink>
      <IconLink href="https://twitter.com/Rosentica">
        <Twitter />
        <span>Twitter</span>
      </IconLink>
      <IconLink href="https://opensea.io/collection/rosentica">
        <Opensea />
        <span>Opensea</span>
      </IconLink>
    </FooterContainer>
  );
};

export default Footer;
