import { useState, useEffect } from "react";
import { usePrepareContractWrite, useContractWrite } from "wagmi";

import { EqualHStack, VerticalStack } from "@components/Layout";
import { unixEpochToDateTime } from "@utils/utils";
import { WLCheckTitle, WLCheckBadge, WLCheckMintableAmount } from "./styled";

import { IMintPhase } from "./types";

const MAX_SUPPLY = 6000;

// When currPhase is 0
const WLCheckPhase = ({
  phaseStartTimes,
  waitlistAmount,
  whitelistAmount,
}: IMintPhase) => {
  return phaseStartTimes && phaseStartTimes.length ? (
    <EqualHStack>
      <VerticalStack>
        <WLCheckTitle>
          You {waitlistAmount === 0 ? "don't " : ""}have{" "}
          <strong>Traveler's List</strong>
        </WLCheckTitle>
        <WLCheckBadge
          imgurl="images/badges/traveler.png"
          have={waitlistAmount > 0}
        />
        <WLCheckMintableAmount>
          <p>
            You can mint <strong>{waitlistAmount}</strong> NFT(s)
          </p>
          <p>on {unixEpochToDateTime(Number(phaseStartTimes[0]))}</p>
        </WLCheckMintableAmount>
      </VerticalStack>
      <VerticalStack>
        <WLCheckTitle>
          You {whitelistAmount === 0 ? "don't " : ""}have{" "}
          <strong>Citizen's Claim</strong>
        </WLCheckTitle>
        <WLCheckBadge
          imgurl="images/badges/citizen.png"
          have={whitelistAmount > 0}
        />
        <WLCheckMintableAmount>
          <p>
            You can mint <strong>{whitelistAmount}</strong> NFT(s)
          </p>
          <p>on {unixEpochToDateTime(Number(phaseStartTimes[1]))}</p>
        </WLCheckMintableAmount>
      </VerticalStack>
      <VerticalStack>
        <WLCheckTitle>
          <strong>Public</strong>
        </WLCheckTitle>
        <WLCheckBadge imgurl="images/badges/public.png" have={true} />
        <WLCheckMintableAmount>
          <p>Anyone can mint</p>
          <p>
            starting {unixEpochToDateTime(Number(phaseStartTimes[2]))}
            ,
            <br />
            FCFS
          </p>
        </WLCheckMintableAmount>
      </VerticalStack>
    </EqualHStack>
  ) : (
    <></>
  );
};

export default WLCheckPhase;
