import { useEffect } from "react";
import { SilhouetteVStack, RotatingSilhouetteBox } from "./styled";

const NUM_SILHOUETTES = 10;
let imagePaths = Array.from(
  { length: NUM_SILHOUETTES },
  (_, i) => `images/silhouettes/silhouette${i}.png`
);
imagePaths = [...imagePaths, "images/fly_NON_AWEBP.webp"];

const RotatingSilhouette = () => {
  useEffect(() => {
    const imagePromises = imagePaths.map((path) => {
      const image = new Image();
      image.src = path;
      return new Promise((resolve) => (image.onload = resolve));
    });

    Promise.all(imagePromises).then(() => {
      // Preloading the silhouette images before the component is mounted so it ain't jittery when it do the transitions via keyframes
    });
  }, []);

  return (
    <SilhouetteVStack>
      <RotatingSilhouetteBox />
    </SilhouetteVStack>
  );
};

export default RotatingSilhouette;
