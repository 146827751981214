import { useState, useEffect } from "react";
import { HorizontalStack } from "@components/Layout";

import { NumberPicker } from "@components/NumberPicker";

import { unixEpochToDateTime } from "@utils/utils";
import {
  MintStage,
  MintingVStack,
  MintAllowance,
  MintingButterfly,
} from "./styled";

import RotatingSilhouette from "../RotatingSilhouette/RotatingSilhouette";
import MintButton from "../MintButton/MintButton";
import MintProgressBar from "../MintProgressBar/MintProgressBar";

import { IMintPhase } from "./types";

const MAX_SUPPLY = 6000;

const MintPhase = ({
  abi,
  contractAddress,
  currPhase,
  getMintableMinMax,
  phaseEndTime,
  proof,
}: IMintPhase) => {
  const { mintableMax, mintableMin } = getMintableMinMax();
  const [mintedAmount, setMintedAmount] = useState(0);
  const [numToMint, setNumToMint] = useState(mintableMin);

  // 0 = Mint button/select number of NFTs to mint stage
  // 1 = Butterfly animation stage (minting)
  // 2 = Successfully minted
  const [currMintStage, setCurrMintStage] = useState(0);

  // Reset mint stage when current phase changes
  useEffect(() => {
    setCurrMintStage(0);
  }, [currPhase]);

  const isMintablePhase = Boolean(currPhase.price);
  const canMint = mintedAmount < MAX_SUPPLY && isMintablePhase;

  useEffect(() => setNumToMint(mintableMin), [mintableMin]);

  return (
    <>
      <MintStage active={currMintStage === 0}>
        <HorizontalStack>
          <RotatingSilhouette />
          <MintingVStack align="start">
            <section>
              <h3>
                {currPhase.name} <span className="live">LIVE</span>
                <div className="enddate">
                  Ends at {unixEpochToDateTime(Number(phaseEndTime))}
                </div>
              </h3>
              <MintProgressBar
                contractAddress={contractAddress}
                abi={abi}
                setMintedAmount={setMintedAmount}
                mintedAmount={mintedAmount}
                canMint={canMint}
              />
            </section>
            <section>
              <h3>You can mint</h3>
              <MintAllowance>{mintableMax}</MintAllowance>
            </section>
            <section>
              <h3>Mint</h3>
              <NumberPicker
                min={mintableMin}
                max={mintableMax}
                value={numToMint}
                onNumberChange={setNumToMint}
              />
            </section>
            <section>
              <MintButton
                numToMint={numToMint}
                contractAddress={contractAddress}
                setCurrMintStage={setCurrMintStage}
                abi={abi}
                proof={proof}
                currPhase={currPhase}
                disabled={numToMint === 0}
              />
            </section>
          </MintingVStack>
        </HorizontalStack>
      </MintStage>
      <MintStage active={currMintStage === 1}>
        <MintingButterfly>
          <img src="images/fly_NON_AWEBP.webp" alt="Blue butterfly" />
          <h1>"Starfall, It's Time."</h1>
          <div>Minting...</div>
        </MintingButterfly>
      </MintStage>
    </>
  );
};

export default MintPhase;
